<!--
 * @Author: 李金深 2896583081@qq.com
 * @Date: 2022-12-30 09:24:55
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2023-02-15 10:42:41
 * @FilePath: /com.huizhouyiren.B0805/src/views/index/pages/navbarPages/CommunityInfo.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="w-full content_page" style="background: #F6F6F6;">
        <!-- <div class="flex justify-center w-full">
            <div class="text-left text-gray-500 path sm:py-4 conent_box">首页-社区</div>
        </div>-->
        <div v-html="community.richText"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            community: {
                "id": 1,
                "communityName": "",
                "picUrl": "",
                "link": "",
                "richText": ""
            },
            id: ""
        };
    },
    computed: {
        ...mapGetters(['getLoginStatus', 'getUserInfo'])
    },
    created() {
        this.id = this.$route.query.id
        this.getDetail()
    },
    methods: {
        // 社区详情
        getDetail() {
            this.$request({
                method: "get",
                url: this.$requestPath.getCommunityInfo,
                params: {
                    id: this.id
                }
            }).then(res => {
                console.log("社区详情", res)
                Object.assign(this.community, res.data)
            }).catch((error) => {
                console.log("记录报错", error)
            })
        },

    },
};
</script>

<style lang='scss' scoped>
.content_page {
    min-height: var(--min-height);
}

.conent_box {
    width: 100%;
    max-width: 75rem;
    padding-left: var(--margin-base);
    padding-right: var(--margin-base);
}

.img_box {
    height: 7.5rem;
    width: 7.5rem;
}
</style>